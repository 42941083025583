<template>
  <div class="overflow-scroll">
    <div style="min-width: 1170px">
      <a-card
        :bordered="false"
        :tabList="tabListNoTitle"
        :activeTabKey="noTitleKey"
        @tabChange="key => onTabChange(key, 'noTitleKey')"
      >
        <a-card
          v-if="noTitleKey === 'baseDetail'"
          :bordered="false"
          style="margin-left: -32px"
        >
          <DescriptionList
            title="品牌信息"
            size="large"
            style="margin-top: -24px;margin-bottom: -48px"
          >
            <a-card
              :bordered="false"
              class="storeNewStoreLabelWidth"
              style="margin-left: -16px"
            >
              <div class="table-operator">
                <a-form layout="inline" :form="form">
                  <a-row :gutter="0" style="line-height: 4">
                    <a-col :md="8" :sm="24">
                      <a-form-item label="选择门店">
                        <a-select
                          @change="getKitchen"
                          style="width: 240px"
                          v-decorator="[
                            'facilityId',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请选择！'
                                }
                              ]
                            }
                          ]"
                          placeholder="请选择"
                        >
                          <a-select-option
                            v-for="item in facilityList"
                            :key="item.id"
                          >
                            {{ item.optionText }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                      <a-form-item label="属性">
                        <a-select
                          @change="ChangeuseScope"
                          style="width: 240px"
                          v-decorator="[
                            'useScope',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请选择！'
                                }
                              ]
                            }
                          ]"
                          placeholder="请选择"
                        >
                          <a-select-option
                            v-for="item in useScopeList"
                            :key="item.id"
                          >
                            {{ item.name }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                      <a-form-item label="档口编号" v-if="this.Iskitchen">
                        <a-select
                          style="width: 240px"
                          v-decorator="[
                            'kitchenId',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请选择！'
                                }
                              ]
                            }
                          ]"
                          placeholder="请选择"
                        >
                          <a-select-option
                            v-for="item in kitchList"
                            :key="item.id"
                          >
                            {{ item.code }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                      <a-form-item label="设备类型">
                        <a-select
                          style="width: 240px"
                          v-decorator="[
                            'type',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请选择！'
                                }
                              ]
                            }
                          ]"
                          placeholder="请选择"
                        >
                          <a-select-option
                            v-for="item in deviceTypeList"
                            :key="item.id"
                          >
                            {{ item.name }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                      <a-form-item label="设备名称">
                        <a-input
                          style="width: 240px"
                          v-decorator="[
                            'deviceName',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！'
                                }
                              ]
                            }
                          ]"
                          placeholder="请输入"
                        >
                        </a-input>
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                      <a-form-item label="是否智能设备">
                        <a-select
                          style="width: 240px"
                          v-decorator="[
                            'enableSmart',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请选择！'
                                }
                              ]
                            }
                          ]"
                          placeholder="请选择"
                        >
                          <a-select-option
                            v-for="item in smartList"
                            :key="item.id"
                          >
                            {{ item.name }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                      <a-form-item label="倍率">
                        <a-input
                          style="width: 240px"
                          v-decorator="[
                            'ratio',
                            {
                              rules: [
                                {
                                  required: true,
                                  pattern: /^[0-9]*[1-9][0-9]*$/,
                                  message: '请输入正整数！'
                                }
                              ]
                            }
                          ]"
                          placeholder="请输入"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                      <a-form-item label="初始读数">
                        <a-input
                          style="width: 240px"
                          v-decorator="[
                            'meterNumber',
                            {
                              rules: [
                                {
                                  required: true,
                                  pattern: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
                                  message: '请输入初始读数！'
                                }
                              ]
                            }
                          ]"
                          placeholder="请输入"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                      <a-form-item label="初始读数日期">
                        <a-date-picker
                          v-decorator="[
                            'meterDate',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请选择初始读数日期！'
                                }
                              ]
                            }
                          ]"
                          show-time
                          format="YYYY-MM-DD"
                        />
                      </a-form-item>
                    </a-col>
                  </a-row>
                </a-form>
              </div>
            </a-card>
          </DescriptionList>
        </a-card>
      </a-card>
      <div style="margin-top: 8px">
        <a-card :bordered="false">
          <div class="steps-action">
            <a-button @click="cancel">
              取消
            </a-button>
            <a-button type="primary" style="margin-left: 8px" @click="conserve">
              保存
            </a-button>
          </div>
        </a-card>
      </div>
    </div>
  </div>
</template>

<script>
import DescriptionList from "../../../components/DescriptionList";
  
import Bus from "../../../bus";
import axios from "axios";

export default {
  name: "hydropowerConfigureNew",
  components: {
    DescriptionList
  },
  data() {
    return {
      tabListNoTitle: [
        {
          key: "baseDetail",
          tab: "基本信息"
        }
      ],
      noTitleKey: "baseDetail",
      form: this.$form.createForm(this),
      defaultData: "",
      //属性
      useScopeList: [
        { id: 1, name: "公摊" },
        { id: 2, name: "自用" },
        { id: 3, name: "总表" }
      ],
      // 档口的显示
      Iskitchen: false,
      // 是否智能设备
      smartList: [
        { id: "Y", name: "是" },
        { id: "N", name: "否" }
      ],
      //设备类型
      deviceTypeList: [
        { id: 1, name: "水表" },
        { id: 2, name: "电表" },
        { id: 3, name: "燃气表" }
      ],
      // 客户名称选择框是否显示
      isPerson: false,
      //门店名称
      brandList: [],
      //经营品类
      cuisineTypeList: [],
      //所属门店
      facilityList: [],
      //店铺状态
      storeStatusList: [],
      //店铺类型
      storeTypeList: [],

      // 客户名称
      personList: [],
      // 关联档口
      kitchList: []
    };
  },
  created() {
    this.getInformation();
    Bus.$on("getTarget", target => {
      this.getInformation();
    });
  },
  methods: {
    //获取所有枚举信息
    getInformation() {
      this.getFacility();
    },
    // 所属门店
    getFacility() {
      axios({
        url:   this.$baseUrl + "facility/querySelect",
        method: "GET"
      }).then(res => {
        if (res.data.rows) {
          this.facilityList = res.data.rows;
        }
      });
    },
    // 根据所在门店获取关联档口
    getKitchen(val) {
      // 如果查询到的档口为空，清空它们之前的选中
      this.kitchList = [];
      const {
        form: { setFieldsValue }
      } = this;
      setFieldsValue({
        kitchenId: ""
      });
      axios({
        method: "GET",
        url:   this.$baseUrl + "/kitchen/querySelect",
        params: {
          page: 1,
          rows: 50,
          facilityId: val
        }
      }).then(res => {
        if (res.data.rows) {
          this.kitchList = res.data.rows;
        } else {
          // this.$message.error(`${res.data.returnMsg}`);
        }
      });
    },
    onTabChange(key, type) {
      this[type] = key;
    },
    // 选择公摊和自用之后的操作
    ChangeuseScope(val) {
      if (val == 3 || val == 1) {
        this.Iskitchen = false;
        // 如果查询到的档口为空，清空它们之前的选中
        const {
          form: { setFieldsValue }
        } = this;
        setFieldsValue({
          kitchenId: ""
        });
      }
      if (val == 2) {
        this.Iskitchen = true;
      }
    },

    //新建
    brandCreate(val) {
      axios({
        method: "POST",
        url:   this.$baseUrl + "/weg/save",
        headers: {
          Accept: "*/*"
        },
        data: {
          ...val
        }
      })
        .then(res => {
          if (res.data.success) {
            this.$router.push({
              path: "/basicConfiguration/hydropowerConfigure",
              query: { reset: true }
            });
            this.$message.success("新建成功！");
          } else {
            this.$message.error(`${res.data.returnMsg}`);
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    conserve() {
      //点击保存
      let that = this;
      const {
        form: { validateFields }
      } = this;
      this.confirmLoading = true;
      validateFields((errors, values) => {
        if (!errors) {
          that.brandCreate(values);
        }
      });
    },
    cancel() {
      // 点击取消
      this.$router.push({
        path: "/basicConfiguration/hydropowerConfigure"
      });
    }
  }
};
</script>

<style scoped>
.steps-action {
  float: right;
}
</style>
